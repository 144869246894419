








































































































































import { Component, Vue } from 'vue-property-decorator'
import FileDetailLayout from '@/views/Group/TopPage/SharedFolder/FileDetailLayout.vue'
import { ShareFolderFileButtonState, State, FileDetail } from '@/models'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'

/**
 * f2-604
 * サークル共有フォルダ - ファイル情報変更
 */
@Component({ components: { FileDetailLayout } })
export default class FileUpdateInformation extends Vue {
  private isCommonTopic = !this.$route.params.groupId
  private actionAreaState: ShareFolderFileButtonState = new ShareFolderFileButtonState()
  private file: FileDetail = new FileDetail()
  private fileTitle: string = ''
  private memo: string = ''
  private notifyUpdate: boolean = false
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false
  private modalMess: string = ''
  private modalAction: any = () => {}

  created() {
    this.handleButtonState()
    this.getFileDetail()
  }

  checkRouterBeforLeave() {
    return this.fileTitle === this.file.title && this.memo === this.file.memo
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmLeave = next
      this.$bvModal.show('file-update-modal-confirm-leave')
    } else {
      next()
    }
  }

  submitUpdateInfo() {
    this.confirmLeaveIgnore = true
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.updateCommonFileInformation({
        file_id: this.$route.params.fileId,
        title: this.fileTitle,
        memo: this.memo,
        notify_update: this.notifyUpdate
      })
        .then(res => {
          if (res.status === 200) {
            this.$bvModal.show('modal-success-update-info')
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.file_not_exist') as string
            this.modalAction = () => this.jumpBack()
            this.confirmLeaveIgnore = true
          } else {
            this.modalMess = this.$t('common.message.update_fail') as string
            this.modalAction = () => {}
          }
          this.$bvModal.show('modal-error-update-info')
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupSharedFolderService.updateInformation({
        group_id: this.$route.params.groupId,
        file_id: this.$route.params.fileId,
        title: this.fileTitle,
        memo: this.memo,
        notify_update: this.notifyUpdate
      })
        .then(res => {
          if (res.status === 200) {
            this.$bvModal.show('modal-success-update-info')
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.file_not_exist') as string
            this.modalAction = () => this.jumpBack()
            this.confirmLeaveIgnore = true
          } else {
            this.modalMess = this.$t('common.message.update_fail') as string
            this.modalAction = () => {}
          }
          this.$bvModal.show('modal-error-update-info')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Check role edit
   */
  checkRoleEdit() {
    if (!(this.$refs.fileDetailLayout as any).roleEditFile) {
      this.confirmLeaveIgnore = true
      this.jumpToFileDetail()
    }
  }

  /**
   * Call API get file detail
   */
  getFileDetail() {
    setTimeout(async () => {
      await (this.$refs.fileDetailLayout as any).getFileDetail()
      this.checkRoleEdit()
      this.file = (this.$refs.fileDetailLayout as any).currentFile
      this.fileTitle = this.file.title
      this.memo = this.file.memo
    }, 1)
  }

  handleButtonState() {
    this.actionAreaState = new ShareFolderFileButtonState({
      fileOverwrite: State.ACTIVE,
      updateInfo: State.DISABLE,
      moveFolder: State.DISABLE,
      moveToTrash: State.HIDDEN
    })
  }

  formatBytes(bytes: number) {
    if (bytes == 0) return '0 KB'
    if (bytes < 1) return '1 KB'
    const k = 1024
    const sizes = ['KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  jumpToParentTopic() {
    if (this.file.topic) {
      if (this.isCommonTopic) {
        this.$router.push({
          name: 'common-topic-new-feed-topic-detail',
          params: {
            topicId: String(this.file.topic.id)
          }
        })
      } else {
        this.$router.push({
          name: 'group-new-feed-topic-detail',
          params: {
            topicId: String(this.file.topic.id)
          }
        })
      }
    }
  }

  jumpToFileDetail() {
    if (this.isCommonTopic) {
      this.$router.push({ name: 'common-topic-shared-folder-file-detail' })
    } else {
      this.$router.push({ name: 'group-shared-folder-file-detail' })
    }
  }

  /**
   * Jump back
   */
  jumpBack() {
    if (this.isCommonTopic) {
      this.$router.push({
        name: 'common-topic-shared-folder'
      })
    } else {
      this.$router.push({
        name: 'group-shared-folder'
      })
    }
  }
}
